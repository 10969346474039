import authorizationInterceptor from './interceptors/authorizationInterceptor'
import domainInterceptor from './interceptors/domainInterceptor'
import responseInterceptor from './interceptors/responseInterceptor'

type RequestInterceptor = (request: Request) => Promise<Request>

type ResponseInterceptor = (
  request: Request,
  response: Response
) => Promise<Response>

const REQUEST_INTERCEPTORS: RequestInterceptor[] = []
const RESPONSE_INTERCEPTORS: ResponseInterceptor[] = []

export const addRequestInterceptor = (interceptor: RequestInterceptor) => {
  REQUEST_INTERCEPTORS.push(interceptor)
}

export const addResponseInterceptor = (interceptor: ResponseInterceptor) => {
  RESPONSE_INTERCEPTORS.push(interceptor)
}

/**
 * Makes a request to the given URL.
 * @param url - The endpoint URL
 * @returns A promise that resolves with the response data or rejects with an error
 * @throws Will throw an error if the request fails
 */
export const request = async (
  input: RequestInfo | URL,
  init?: RequestInit
): Promise<Response> => {
  let context = new Request(input, init || {})

  // Calls the request interceptors
  for (const interceptor of REQUEST_INTERCEPTORS) {
    context = await interceptor(context)
  }

  // Don't send any content type if there is not set. Otherwise we should use
  // application/json
  const contentType = context.headers.get('Content-type')
  if (contentType === null) {
    context.headers.set('Content-type', 'application/json')
  } else if (contentType.length === 0) {
    context.headers.delete('Content-type')
  }

  // Send the request
  let response = await window.originalFetch(context)

  // Calls the response interceptors
  for (const interceptor of RESPONSE_INTERCEPTORS) {
    response = await interceptor(context, response)
  }

  return response
}

export const setupRequests = () => {
  addRequestInterceptor(domainInterceptor)
  addRequestInterceptor(authorizationInterceptor)
  addResponseInterceptor(responseInterceptor)
}
